import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosInstance/axiosInstance";
import AddUser from "../Forms/AddUser";
import UpdateUser from "../Update/UpdateUser";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AnimatePresence } from "framer-motion";
import AddStaff from "../Forms/AddStaff";
import UpdateStaff from "../Update/UpdateStaff";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ImageUploadModal from "./ModalForimage";

const UserListingPage = ({ menus }) => {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDisignationOpen, setIsDesignationOpen] = useState(false);
  const [isAddUserVisible, setIsAddUserVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [AddStaffModal, setAddStaffModal] = useState(false);
  const [updateStaffModal, setUpdateStaffModal] = useState(false);
  const [staff, setStaff] = useState([]);
  const [activeTab, setActiveTab] = useState("associate");
  const [companySettings, setCompanySettings] = useState(null);
  const [clientFee, setClientFee] = useState("");
  const [mentors, setMentors] = useState([]);
  const [banners, setBanners] = useState([]);

  const fetchBanners = async () => {
    try {
      const response = await axiosInstance.get("/account/api/v1/home-banners/");
      setBanners(response.data); // Assuming response.data is an array
      console.log("i am akhilesh haiii",response.data)
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };
  useEffect(() => {
    fetchBanners();
  }, []);
  const handleDeletebanner = async (id) => {
    try {
      await axiosInstance.delete(`/account/api/v1/remove-home-hanner/${id}/`);

      // Update the banners state to remove the deleted item
      setBanners((prevBanners) =>
        prevBanners.filter((banner) => banner.id !== id)
      );

      console.log(`Banner with ID ${id} deleted successfully.`);
    } catch (error) {
      console.error("Error deleting banner:", error);
    }
  };
  const handleBannerUploadSuccess = () => {
    fetchBanners(); // Re-fetch banners after successful upload
  };

  const fetchMentrorList = async () => {
    try {
      const response = await axiosInstance.get(
        "account/api/v1/mentor-request-list/"
      );
      setMentors(response.data);
    } catch (err) {
      console.log("erro fetching api", err.message);
    }
  };
  useEffect(() => {
    if (activeTab === "adminverification") {
      fetchMentrorList();
    }
  }, [activeTab]); // Run when activeTab changes

  const verifyMentor = async (id) => {
    try {
      const response = await axiosInstance.put(
        `/account/api/v1/verify-mentor-user/${id}/`,
        {
          status: "Accepted", // Sending status in the request body
        }
      );

      if (response.status === 200) {
        console.log("Mentor verified successfully!");
        fetchMentrorList(); // Fetch updated mentor list after successful verification
      }
    } catch (error) {
      console.log("Failed to verify mentor.");
      console.error("Error verifying mentor:", error);
    }
  };

  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [imageUPmodal, setImageUPmodal] = useState(false);

  const [deletionType, setDeletionType] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [banner_url, setBannerUrlSet] = useState(null);

  const categoryDropdownRef = useRef(null);
  const destinationDropdownRef = useRef(null);
  const fetchCompanySettings = async () => {
    try {
      const response = await axiosInstance.get(
        "/account/api/v1/company-settings/"
      );
      setClientFee(response.data.client_fee);
      setCompanySettings(response.data);
    } catch (error) {
      console.error("Error fetching company settings:", error);
    }
  };
  useEffect(() => {
    if (activeTab === "companysettings") fetchCompanySettings();
  }, [activeTab]);

  const handleClickOutside = (event) => {
    if (
      categoryDropdownRef.current &&
      !categoryDropdownRef.current.contains(event.target)
    ) {
      setIsCategoryOpen(false);
    }
    if (
      destinationDropdownRef.current &&
      !destinationDropdownRef.current.contains(event.target)
    ) {
      setIsDesignationOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
          document.documentElement.scrollHeight - 1 &&
        nextUrl
      ) {
        fetchStaffData(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [nextUrl, loading]);

  useEffect(() => {
    fetchStaffData();
  }, []);

  // __________________________________________________

  // const fetchData = async (reset = true) => {

  //     console.log("fetch data", reset);
  //     setLoading(true);
  //     const url = reset ? '/account/api/v1/users/?limit=10&offset=0' : nextUrl;

  //     try {
  //         const response = await axiosInstance.get(url);

  //         const filteredClients = response.data.results;
  //         console.log("Filtered Clients:", filteredClients);

  //         // Update data correctly
  //         setData(prevData => (reset ? filteredClients : [...prevData, ...filteredClients]));

  //         setNextUrl(response.data.next);

  //     } catch (error) {
  //         console.error('Error fetching users:', error);
  //         setError(error);
  //         setLoading(false);
  //     }
  // };

  // useEffect(() => {
  //     fetchData();
  // }, []);

  const fetchData = async (reset = true) => {
    console.log("fetch data", reset);
    setLoading(true);
    const offset = reset ? 0 : data.length;
    const url = reset
      ? `/account/api/v1/users/?limit=10&offset=${offset}&user_type=${
          activeTab === "associate" ? "agency" : "staff"
        }`
      : nextUrl;

    try {
      const response = await axiosInstance.get(url);
      const filteredClients = response.data.results;
      console.log("Filtered Clients:", filteredClients);

      // Update data correctly
      setData((prevData) =>
        reset ? filteredClients : [...prevData, ...filteredClients]
      );
      setNextUrl(response.data.next); // Update next URL for pagination
      setPreviousUrl(response.data.previous); // Update previous URL

      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setError(error);
      setLoading(false);
    }
  };

  const fetchStaffData = async (reset = true) => {
    console.log("fetch staff data", reset);
    setLoading(true);
    const offset = reset ? 0 : staff.length;
    const url = reset
      ? `/account/api/v1/users/?limit=10&offset=${offset}&user_type=staff`
      : nextUrl;

    try {
      const response = await axiosInstance.get(url);
      const filteredStaff = response.data.results;
      console.log("Filtered Staff:", filteredStaff);

      // Update staff correctly
      setStaff((prevData) =>
        reset ? filteredStaff : [...prevData, ...filteredStaff]
      );
      setNextUrl(response.data.next); // Update next URL for pagination
      setPreviousUrl(response.data.previous); // Update previous URL

      setLoading(false);
    } catch (error) {
      console.error("Error fetching staff:", error);
      setError(error);
      setLoading(false);
    }
  };

  // Handle Pagination Logic (Next/Previous)
  const handlePagination = (direction) => {
    if (direction === "next" && nextUrl) {
      fetchData(false); // Fetch the next page
    } else if (direction === "previous" && previousUrl) {
      fetchData(false); // Fetch the previous page
    }
  };
  const handleStaffPagination = (direction) => {
    if (direction === "next" && nextUrl) {
      fetchStaffData(false); // Fetch the next page
    } else if (direction === "previous" && previousUrl) {
      fetchStaffData(false); // Fetch the previous page
    }
  };

  useEffect(() => {
    // Fetch data based on the active tab
    if (activeTab === "associate") {
      fetchData();
    } else if (activeTab === "staff") {
      fetchStaffData();
    }
  }, [activeTab]);

  // useEffect(() => {
  //     const handleScroll = () => {
  //         // Check if we reached the bottom of the page and if more data is available
  //         if (
  //             window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 &&
  //             nextUrl && !loading
  //         ) {
  //             if (activeTab === "associate") {
  //                 fetchData(false); // Fetch next set of data for associates
  //             } else if (activeTab === "staff") {
  //                 fetchStaffData(false); // Fetch next set of data for staff
  //             }
  //         }
  //     };

  //     window.addEventListener('scroll', handleScroll);

  //     // Clean up event listener on component unmount
  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, [nextUrl, loading, activeTab]);

  useEffect(() => {
    setModalVisible(false);
  }, []);

  const handleCloseModal = () => {
    setSelectedUserData(null);
    setModalVisible(false);
  };

  const toggleAddUserVisibility = () => {
    setIsAddUserVisible(!isAddUserVisible);
  };
  const toggleAddStaffVisibility = () => {
    setAddStaffModal(!AddStaffModal);
  };

  const handleClose = () => {
    setIsAddUserVisible(false);
  };

  const toggleListCategory = (itemId) => {
    setIsCategoryOpen(itemId === isCategoryOpen ? null : itemId);
  };

  const toggleListDesignation = (itemId) => {
    setIsDesignationOpen(itemId === isDisignationOpen ? null : itemId);
  };

  const handleCategoryChange = async (categoryID, user) => {
    try {
      console.log("client", user);
      await axiosInstance.patch(
        `/account/api/v1/user-detail-update/${user.id}/`,
        { category: categoryID }
      );
      console.log("Category updated successfully");
      setIsCategoryOpen(false);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const handleDesignationChange = async (designationID, user) => {
    try {
      console.log("user", user);
      await axiosInstance.patch(
        `/account/api/v1/user-detail-update/${user.id}/`,
        { designation: designationID }
      );
      console.log("designation updated successfully");
      setIsDesignationOpen(false);
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  const [options, setOptions] = useState({
    categories: [],
    designations: [],
  });

  const handleButtonClick = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/account/api/v1/user-detail-update/${id}/`
      );
      setSelectedUserData(response.data);
      console.log(response.data, "oooiiii abhi");
      setModalVisible(true);
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  const handleStaffClick = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/account/api/v1/user-detail-update/${id}/`
      );
      setSelectedUserData(response.data);
      console.log(response.data, "oooiiii abhi");
      setUpdateStaffModal(true);
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const categoriesResponse = await axiosInstance.get(
          "kanban/api/v1/category_dropdown/?limit=&search="
        );
        setOptions((prevOptions) => ({
          ...prevOptions,
          categories: categoriesResponse.data,
        }));
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    const fetchDesignationOptions = async () => {
      try {
        const designationsResponse = await axiosInstance.get(
          "/kanban/api/v1/designation_dropdown/"
        );
        setOptions((prevOptions) => ({
          ...prevOptions,
          designations: designationsResponse.data,
        }));
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
    fetchDesignationOptions();
  }, []);
  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axiosInstance.post(
        "home/api/v1/upload-file/",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return response.data.image_url; // Return the URL if successful
    } catch (error) {
      console.error("Error uploading image:", error);
      return null; // Return null if an error occurs
    }
  };
  //    const handleInputChange = async (e, key) => {
  //     if (key === 'client_fee') {
  //         setClientFee(e.target.value);
  //     } else if (key === 'banner_url') {
  //         const value = e.target.value;
  //         setBannerUrlSet(value);
  //         setCompanySettings((prev) => ({
  //             ...prev,
  //             [key]: value,
  //         }));
  //     } else if (e.target.files) {
  //         const file = e.target.files[0];
  //         if (file) {
  //             const uploadedUrl = await uploadImage(file);
  //             if (uploadedUrl) {
  //                 setCompanySettings((prev) => ({
  //                     ...prev,
  //                     [key]: uploadedUrl,
  //                 }));
  //             }
  //         }
  //     }
  // };
  const handleInputChange = async (e, key) => {
    if (key === "client_fee") {
      setClientFee(e.target.value);
    } else if (key === "banner_url") {
      const value = e.target.value;
      setBannerUrlSet(value);
      setCompanySettings((prev) => ({
        ...prev,
        [key]: value,
      }));
    } else if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        const uploadedUrl = await uploadImage(file);
        if (uploadedUrl) {
          setCompanySettings((prev) => ({
            ...prev,
            [key]: uploadedUrl,
          }));
        }
      }
    }
  };

  const updateCompanySettings = async () => {
    setLoading(true);
    try {
      const updatedData = {
        client_fee: clientFee,
        web_banner_img: companySettings.web_banner_img,
        mobile_banner_img: companySettings.mobile_banner_img,
        web_logo: companySettings.web_logo,
        mobile_logo: companySettings.mobile_logo,
        banner_url: banner_url,
      };

      await axiosInstance.patch(
        "/account/api/v1/company-settings/",
        updatedData
      );
      alert("Company settings updated successfully!");
    } catch (error) {
      console.error("Error updating company settings:", error);
      alert("Failed to update company settings.");
    } finally {
      setLoading(false);
    }
  };
  // const handleDelete = async (id) => {
  //     try {
  //         await axiosInstance.patch(`account/api/v1/user-detail-update/${id}/`, { is_active: false });
  //         setData(prevData =>
  //             prevData.map(item =>
  //                 item.id === id ? { ...item, is_active: false } : item
  //             )
  //         );
  //     } catch (error) {
  //         console.error('Error deleting user:', error);
  //     }
  // };
  const confirmDeactivation = async () => {
    try {
      console.log("ID being passed:", selectedId);

      const response = await axiosInstance.patch(
        `account/api/v1/user-detail-update/${selectedId}/`,
        { is_deleted: true }
      );

      if (response.status === 200) {
        setShowModal(false); // Close the modal on success

        // Refresh the appropriate data list
        if (deletionType === "associate") {
          fetchData();
        } else if (deletionType === "staff") {
          fetchStaffData();
        }
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  const handleDelete = (id, type) => {
    setDeletionType(type);
    setShowModal(true);
    setSelectedId(id);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col w-full pt-4  gap-6 relative z-0  ">
      <div className="pl-10">
        <div className="bg-gray-50 rounded-md flex justify-center">
          <div
            className="inter-font px-4 flex gap-20 whitespace-nowrap pt-6 text-center text-[11px] text-gray-400 w-full"
            style={{
              borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
              width: "100%",
            }}
          >
            <div
              className={`cursor-pointer pb-2 ${
                activeTab === "associate"
                  ? "text-black border-b-4  border-green-500"
                  : ""
              }`}
              onClick={() => setActiveTab("associate")}
            >
              Associate
            </div>
            <div
              className={`cursor-pointer pb-2 ${
                activeTab === "staff"
                  ? "text-black border-b-4 border-green-500"
                  : ""
              }`}
              onClick={() => setActiveTab("staff")}
            >
              Staff
            </div>
            <div
              className={`cursor-pointer pb-2 ${
                activeTab === "companysettings"
                  ? "text-black border-b-4 border-green-500"
                  : ""
              }`}
              onClick={() => setActiveTab("companysettings")}
            >
              Company Settings
            </div>
            <div
              className={`cursor-pointer pb-2 ${
                activeTab === "adminverification"
                  ? "text-black border-b-4 border-green-500"
                  : ""
              }`}
              onClick={() => setActiveTab("adminverification")}
            >
              Admin Verification
            </div>
            <div
            // className={`cursor-pointer pb-2 ${activeTab === "department" ? "text-black border-b-4 border-green-500" : ""
            //     }`}
            // onClick={() => setActiveTab("department")}
            >
              Department
            </div>
            <div
            // className={`cursor-pointer pb-2 ${activeTab === "stages" ? "text-black border-b-4 border-green-500" : ""
            //     }`}
            // onClick={() => setActiveTab("stages")}
            >
              Stages
            </div>
          </div>
        </div>
      </div>

      {/* first section */}
      {activeTab === "associate" && (
        <div>
          <div className="overflow-x-auto flex-grow h-[450px] pl-10  ">
            <table className=" w-full divide-y divide-gray-200 bg-gray-50  ">
              <thead>
                <tr>
                  <th className="inter-font whitespace-nowrap px-4 py-3  text-left text-[11px] text-gray-400">
                    #
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left  text-[11px] text-gray-400">
                    Logo
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Created at
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Name
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Email ID
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Balance
                  </th>

                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Phone Number
                  </th>
                  {/* <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Designation</th>
                                <th className="inter-font flex justify-center items-center whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400 ">
                                    Category
                                    <span ><ArrowDropDownIcon style={{ fontSize: '20px', color: 'black' }} /></span>
                                </th> */}
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left  text-[11px] text-gray-400">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className="border-b-2 border-gray-100 bg-white"
                  >
                    <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">
                      {index + 1}
                    </td>
                    <td className="inter-light whitespace-nowrap px-4 py-3 text-center">
                      <img
                        src={item.profile_img}
                        alt="Logo"
                        className="w-10 h-10 object-cover rounded-full"
                      />
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.created_at}
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.full_name}
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.email}
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.wallet_balance}
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.phone}
                    </td>
                    {/* <td className='relative'>
                                        <div className="flex justify-center">
                                            <button
                                                onClick={() => toggleListDesignation(item.id)}
                                                style={{
                                                    borderRadius: '60px', fontSize: '11px', display: 'flex', alignItems: "center", justifyContent: "center", width: '70px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                                }}
                                            >
                                                <div className='inter-light' style={{ color: 'black', fontSize: '12px' }}>
                                                    {item.designation ? item.designation.name : "Select designation"}
                                                </div>
                                            </button>
                                            {isDisignationOpen === item.id && (
                                                <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={destinationDropdownRef}>
                                                    <ul className="py-2">
                                                        {loading ? (
                                                            <li>Loading...</li>
                                                        ) : error ? (
                                                            <li>Error: {error.message}</li>
                                                        ) : (
                                                            options.designations.map((designation) => (
                                                                <li
                                                                    key={designation.id}
                                                                    className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                    onClick={() => handleDesignationChange(designation.id, item)}
                                                                >
                                                                    <div className='flex justify-start items-center gap-1'>
                                                                        <div style={{ width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                        <div className='inter-font'>{designation.name}</div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    <td className='relative'>
                                        <div className="flex justify-center">
                                            <button
                                                onClick={() => toggleListCategory(item.id)}
                                                style={{
                                                    backgroundColor: `#${item.category?.bg_color}`, borderRadius: '60px', fontSize: '12px', display: 'flex', alignItems: "center", justifyContent: "center", width: '90px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                                }}
                                            >
                                                <div className='inter-light' style={{ color: `#${item.category?.color_code}`, fontSize: '12px' }}>
                                                    {item.category ? item.category.name : "Select Category"}
                                                </div>
                                            </button>
                                            {isCategoryOpen === item.id && (
                                                <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={categoryDropdownRef}>
                                                    <ul className="py-2">
                                                        {loading ? (
                                                            <li>Loading...</li>
                                                        ) : error ? (
                                                            <li>Error: {error.message}</li>
                                                        ) : (
                                                            options.categories.map((category) => (
                                                                <li
                                                                    key={category.id}
                                                                    className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                    onClick={() => handleCategoryChange(category.id, item)}
                                                                >
                                                                    <div className='flex justify-start items-center gap-1'>
                                                                        <div style={{ backgroundColor: `#${category.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                        <div className='inter-font'>{category.name}</div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    </td> */}

                    <td className="inter-font whitespace-nowrap  py-3  text-[10px] text-black tracking-wider font-semibold">
                      <button
                        type="button"
                        onClick={() => handleButtonClick(item.id)}
                        className="  text-blue-500   text-[11px] px-2 py-1 text-center  underline"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDelete(item.id, "associate")}
                        className="  text-red-500   text-[11px] px-2 py-1 text-center  underline"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {nextUrl && (
            <div
              className="flex justify-end   text-[12px] px-2 py-1 inter-light  underline cursor-pointer"
              onClick={() => handlePagination("next")}
            >
              {" "}
              Click to Scroll more
            </div>
          )}

          <div className="flex justify-center items-center inter-font py-4 ">
            <button
              type="button"
              onClick={toggleAddUserVisibility}
              className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
            >
              Add Associate
            </button>
            {/* <button
                            type="button"
                            onClick={() => handlePagination("next")}
                            disabled={!nextUrl}
                            className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                        >
                            Next
                        </button> */}
          </div>
        </div>
      )}

      {/* // second section */}

      {activeTab === "staff" && (
        <div>
          <div className="overflow-x-auto flex-grow h-[400px] pl-10">
            <table className="w-full divide-y divide-gray-200 bg-gray-50   ">
              <thead>
                <tr>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    #
                  </th>

                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Created at
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Name
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Email ID
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Phone Number
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Designation
                  </th>
                  <th className="inter-font flex justify-center text-left whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400 ">
                    Category
                    <span>
                      <ArrowDropDownIcon
                        style={{ fontSize: "20px", color: "black" }}
                      />
                    </span>
                  </th>
                  <th className="inter-font whitespace-nowrap px-4 py-3 text-left text-[11px] text-gray-400">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {staff.map((item, index) => (
                  <tr
                    key={index}
                    className="border-b-2 border-gray-100 bg-white"
                  >
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {index + 1}
                    </td>

                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.created_at}
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.full_name}
                    </td>
                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.email}
                    </td>

                    <td className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                      {item.phone}
                    </td>
                    <td className="relative">
                      <div className="flex ">
                        <button
                          onClick={() => toggleListDesignation(item.id)}
                          style={{
                            borderRadius: "60px",
                            fontSize: "11px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "70px",
                            paddingBottom: "0.5px",
                            paddingTop: "0.5px",
                          }}
                        >
                          <div
                            className="inter-light"
                            style={{ color: "black", fontSize: "12px" }}
                          >
                            {item.designation
                              ? item.designation.name
                              : "Select designation"}
                          </div>
                        </button>
                        {isDisignationOpen === item.id && (
                          <div
                            className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap"
                            style={{ width: "90px" }}
                            ref={destinationDropdownRef}
                          >
                            <ul className="py-2">
                              {loading ? (
                                <li>Loading...</li>
                              ) : error ? (
                                <li>Error: {error.message}</li>
                              ) : (
                                options.designations.map((designation) => (
                                  <li
                                    key={designation.id}
                                    className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleDesignationChange(
                                        designation.id,
                                        item
                                      )
                                    }
                                  >
                                    <div className="flex justify-start items-center gap-1">
                                      <div
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                          borderRadius: "80px",
                                        }}
                                      ></div>
                                      <div className="inter-font">
                                        {designation.name}
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="relative">
                      <div className="flex ">
                        <button
                          onClick={() => toggleListCategory(item.id)}
                          style={{
                            backgroundColor: `#${item.category?.bg_color}`,
                            borderRadius: "60px",
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "90px",
                            paddingBottom: "0.5px",
                            paddingTop: "0.5px",
                          }}
                        >
                          <div
                            className="inter-light"
                            style={{
                              color: `#${item.category?.color_code}`,
                              fontSize: "12px",
                            }}
                          >
                            {item.category
                              ? item.category.name
                              : "Select Category"}
                          </div>
                        </button>
                        {isCategoryOpen === item.id && (
                          <div
                            className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap"
                            style={{ width: "90px" }}
                            ref={categoryDropdownRef}
                          >
                            <ul className="py-2">
                              {loading ? (
                                <li>Loading...</li>
                              ) : error ? (
                                <li>Error: {error.message}</li>
                              ) : (
                                options.categories.map((category) => (
                                  <li
                                    key={category.id}
                                    className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleCategoryChange(category.id, item)
                                    }
                                  >
                                    <div className="flex justify-start items-center gap-1">
                                      <div
                                        style={{
                                          backgroundColor: `#${category.bg_color}`,
                                          width: "10px",
                                          height: "10px",
                                          borderRadius: "80px",
                                        }}
                                      ></div>
                                      <div className="inter-font">
                                        {category.name}
                                      </div>
                                    </div>
                                  </li>
                                ))
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    </td>

                    <td className="inter-font whitespace-nowrap  py-3  text-[10px] text-black tracking-wider font-semibold">
                      <button
                        type="button"
                        onClick={() => handleStaffClick(item.id)}
                        className="  text-blue-500   text-[11px] px-2 py-1 text-center  underline"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDelete(item.id, "staff")}
                        className="  text-red-500   text-[11px] px-2 py-1 text-center  underline"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {nextUrl && (
            <div
              className="flex justify-end   text-[12px] px-2 py-1 inter-light  underline cursor-pointer"
              onClick={() => handleStaffPagination("next", "staff")}
            >
              {" "}
              Click to Scroll more
            </div>
          )}

          <div className="flex justify-center items-center inter-font py-4 ">
            <button
              type="button"
              onClick={toggleAddStaffVisibility}
              className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
            >
              Add Staff
            </button>
          </div>
        </div>
      )}

      <div>
        {activeTab === "companysettings" && companySettings && (
          <div className="p-2 pl-16">
            <div className="py-10">
              <div>
                <label
                  htmlFor="webBanner"
                  className="block mb-2 text-[13px] inter-font"
                >
                  Upload Banners Here
                </label>
                <div
                  className="flex items-center justify-center w-80 "
                  onClick={() => {
                    setImageUPmodal(!imageUPmodal);
                  }}
                >
                  <div className="flex flex-col items-center justify-center bg-white w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative">
                    <div className="text-gray-500 text-center">
                      <img src="Camera.svg" alt="cameraicon" />
                    </div>
                  </div>
                </div>

                <ImageUploadModal
                  open={imageUPmodal}
                  onClose={() => setImageUPmodal(false)}
                  onUploadSuccess={handleBannerUploadSuccess}
                />

                <div>
                  <div className="container mx-auto p-4">
                    {banners.length > 0 ? (
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        {banners.map((banner) => (
                          <div
                            key={banner.id}
                            className="border-2 p-4 rounded-lg shadow-md flex flex-col justify-between"
                          >
                            <div className="flex items-center justify-center">
                              <img
                                className="w-40 h-40 object-contain"
                                src={banner.web_banner_img || "Camera.svg"}
                                alt="Banner"
                              />
                            </div>
                            <div className="text-[16px] text-gray-500 mt-4">
                              Click to action:{" "}
                              <a
                                href={banner.banner_url}
                                className="text-blue-600 underline break-words"
                              >
                                {banner.banner_url}
                              </a>
                            </div>
                            <div className="flex flex-row justify-between items-center mt-4">
                              <div className="text-[16px] text-gray-500">
                                Page: {banner.page.name}
                              </div>
                              <img
                                className="w-7 cursor-pointer"
                                onClick={() => handleDeletebanner(banner.id)}
                                src="delete.svg"
                                alt="Delete"
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-center text-gray-500 col-span-full">
                        No banners found.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* 
            <div className="grid grid-cols-2 gap-6">
              
              <div>
                <label
                  htmlFor="webBanner"
                  className="block mb-2 text-[13px] inter-font"
                >
                  Web Banner
                </label>
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="webBanner"
                    className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                  >
                    {companySettings.web_banner_img ? (
                      <img
                        src={companySettings.web_banner_img}
                        alt="Web Banner"
                        className="h-full w-full object-contain rounded-md px-2 py-2"
                      />
                    ) : (
                      <div className="text-gray-500 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-10 h-10 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Click to upload web banner</p>
                      </div>
                    )}
                    <input
                      id="webBanner"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => handleInputChange(e, "web_banner_img")}
                    />
                  </label>
                </div>
              </div>

           
              <div>
                <label
                  htmlFor="mobileBanner"
                  className="block mb-2 text-[13px] inter-font"
                >
                  Mobile Banner
                </label>
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="mobileBanner"
                    className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                  >
                    {companySettings.mobile_banner_img ? (
                      <img
                        src={companySettings.mobile_banner_img}
                        alt="Mobile Banner"
                        className="h-full w-full object-contain rounded-md px-2 py-2"
                      />
                    ) : (
                      <div className="text-gray-500 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-10 h-10 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Click to upload mobile banner</p>
                      </div>
                    )}
                    <input
                      id="mobileBanner"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) =>
                        handleInputChange(e, "mobile_banner_img")
                      }
                    />
                  </label>
                </div>
              </div>

            
              <div>
                <label
                  htmlFor="webLogo"
                  className="block mb-2 text-[13px] inter-font"
                >
                  Web Logo
                </label>
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="webLogo"
                    className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                  >
                    {companySettings.web_logo ? (
                      <img
                        src={companySettings.web_logo}
                        alt="Web Logo"
                        className="h-full w-full object-contain rounded-md px-2 py-2"
                      />
                    ) : (
                      <div className="text-gray-500 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-10 h-10 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Click to upload web logo</p>
                      </div>
                    )}
                    <input
                      id="webLogo"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => handleInputChange(e, "web_logo")}
                    />
                  </label>
                </div>
              </div>

              <div>
                <label
                  htmlFor="mobileLogo"
                  className="block mb-2 text-[13px] inter-font"
                >
                  Mobile Logo
                </label>
                <div className="flex items-center justify-center w-full">
                  <label
                    htmlFor="mobileLogo"
                    className="flex flex-col items-center justify-center bg-green-50 w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-black relative"
                  >
                    {companySettings.mobile_logo ? (
                      <img
                        src={companySettings.mobile_logo}
                        alt="Mobile Logo"
                        className="h-full w-full object-contain rounded-md px-2 py-2"
                      />
                    ) : (
                      <div className="text-gray-500 text-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-10 h-10 text-gray-400"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm10 7a1 1 0 00-1-1H7a1 1 0 100 2h6a1 1 0 001-1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <p>Click to upload mobile logo</p>
                      </div>
                    )}
                    <input
                      id="mobileLogo"
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => handleInputChange(e, "mobile_logo")}
                    />
                  </label>
                </div>
              </div>

          
              <div className="flex flex-col">
                <label className="block text-[13px] inter-font text-gray-700">
                  BannerURL
                </label>
                <input
                  value={banner_url}
                  type="text"
                  onChange={(e) => setBannerUrlSet(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />

                <label className="block text-[13px] inter-font text-gray-700 mt-2">
                  Client Fee
                </label>
                <input
                  type="number"
                  value={clientFee}
                  onChange={(e) => setClientFee(e.target.value)}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                />
              </div>
            </div> */}

            {/* <div className="flex justify-end px-4 py-4">
              <button
                onClick={updateCompanySettings}
                disabled={loading}
                className="px-4 py-2 bg-green-500 text-black text-[13px] rounded-md inter-font"
              >
                {loading ? "Saving..." : "Save Changes"}
              </button>
            </div> */}
          </div>
        )}
      </div>
      <div>
        {activeTab === "adminverification" && (
          <div className="h-[80vh] ">
            {mentors.map((mentor, index) => (
              <div>
                <div key={mentor.id} className="pl-[40px] ">
                  <div className="flex justify-between shadow-md items-center py-2 px-2   ">
                    <div className="flex items-center gap-4  ">
                      <div className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">
                        {index + 1}
                      </div>
                      <div className="inter-light whitespace-nowrap px-4 py-3 text-center">
                        <img
                          src={mentor.mentor?.profile_img}
                          alt="profile"
                          className="w-10 h-10 object-cover rounded-full"
                        />
                      </div>
                      <div>
                        <span className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                          {mentor.mentor?.user?.first_name}{" "}
                          {mentor.mentor?.user?.last_name}
                        </span>
                      </div>
                      <div>
                        <span className="inter-light  whitespace-nowrap px-4 py-3  text-[12px] text-black  tracking-wider ">
                          {mentor.mentor?.user?.email}
                        </span>
                      </div>
                    </div>

                    <div className="flex  gap-3 items-center">
                      <div className="inter-light bg-red-600  whitespace-nowrap px-2 py-1 rounded-full  text-[12px] text-white  tracking-wider ">
                        {mentor.status}
                      </div>
                      <div>
                        <button
                          className="text-black-600 inter-light bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-[13px] px-5 py-2 text-center dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                          onClick={() => verifyMentor(mentor.id)}
                        >
                          Verify
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-8 rounded-md shadow-md border border-black border-2 m-1">
            <div className="flex">
              <div>
                <DeleteForeverIcon style={{ color: "red" }} />
              </div>
              <div className="inter-light pb-6 mt-1 text-[15px]">
                {deletionType === "associate"
                  ? "Are you sure you want to delete this Associate?"
                  : "Are you sure you want to delete this Staff?"}
              </div>
            </div>
            <div className="flex justify-end gap-4">
              <button
                onClick={confirmDeactivation}
                className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
              >
                Delete
              </button>
              <button
                onClick={closeModal}
                className="bg-black text-white font-bold py-1 px-8 text-[13px] rounded-sm inter-light"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      <AnimatePresence>
        {isAddUserVisible && (
          <div className="inset-0 z-50 flex items-center justify-center">
            <AddUser
              onClose={handleClose}
              setIsAddUserVisible={setIsAddUserVisible}
              handleButtonClick={handleButtonClick}
              fetchData={fetchData}
            />
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {modalVisible && (
          <div className="inset-0 z-50 flex items-center justify-center ">
            <UpdateUser
              selectedUserData={selectedUserData}
              setSelectedUserData={setSelectedUserData}
              onClose={handleCloseModal}
              setModalVisible={setModalVisible}
              fetchData={fetchData}
            />
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {AddStaffModal && (
          <div className="inset-0 z-50 flex items-center justify-center">
            <AddStaff
              onClose={handleClose}
              setIsAddUserVisible={setIsAddUserVisible}
              handleButtonClick={handleButtonClick}
              fetchStaffData={fetchStaffData}
              setAddStaffModal={setAddStaffModal}
            />
          </div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {updateStaffModal && (
          <div className="inset-0 z-50 flex items-center justify-center ">
            <UpdateStaff
              selectedUserData={selectedUserData}
              setSelectedUserData={setSelectedUserData}
              onClose={handleCloseModal}
              setModalVisible={setModalVisible}
              fetchStaffData={fetchStaffData}
              setUpdateStaffModal={setUpdateStaffModal}
            />
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserListingPage;